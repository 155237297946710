<template v-slot:top>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="4"> Patients </v-col>
          <v-col offset-md="6" md="2">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="patients"      
        class="elevation-1"
        :server-items-length="totalPatients"
        :options.sync="options"
        :loading="loading"
        :search="search"
        :footer-props="{
          'items-per-page-options': rowsPerPage
        }"  
      >
        <template v-slot:top>
          <v-dialog v-model="dialogPatient" v-if="dialogPatient"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialogPatient = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title
                  >{{ editedItem.name }} -
                  <span class="subtitle-2">{{ editedItem.customer }}</span></v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="dialogPatient = false">
                    Close
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <PatientDetails />
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="viewPatient(item)">
            mdi-pencil
          </v-icon>
        </template>
        <template #[`item.dob`]="{ item }">
           {{ item.dob != null?new Date(item.dob).toLocaleDateString("en-US"):''}}         
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>


<script>
import Vue from "vue";
import PatientDetails from "./PatientDetails";
import CustomerPatientsService from "../services/CustomerPatientsService"

export default Vue.extend({
  name: "Patients",
  components: {
    PatientDetails,
  },
  data: () => ({
    loading: true,
    totalPatients:0,
    options:{},
    search: "",
    dialog: false,
    dialogPatient: false,
    headers: [
      {
        text: "Patient Name",
        align: "start",
        value: "patientName",
      },
      { text: "Patient Designation", value: "patientDesignation" },     
      { text: "DOB", value: "dob" },
      { text: "Customer", value: "customerName" },
      { text: "State", value: "patientState" },
      { text: "Scan State", value: "patientScanState" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    patients: [],
    editedIndex: -1,
    editedItem: {
      patientName: "",
      patientDesignation: "",
      gender: "",
      dob: "",
      customerName: "",
      patientState: "",
      patientScanState:""
    },
    defaultItem: {
      patientName: "",
      patientDesignation: "",
      gender: "",
      dob: "",
      customerName: "",
      patientState: "",
      patientScanState:""
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Patient" : "Edit Patient";
    },

    rowsPerPage() {
        return this.$rowsPerPage;
    },
  },

  watch: {
   options: {
      handler () {        
        this.getPatients();
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search:{
      handler (val) {
        if(!val)
          return;
        this.fetchEntriesDebounced();
      },
      deep: true,
    }
  },

  methods: {

   fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getPatients();
      }, 500)
    },
    getPatients(){
      this.loading = true;
      let pagedOptions = {Search: this.search, 
                          SortBy: this.options.sortBy.length > 0 ? this.options.sortBy[0]:null,
                          SortDesc:this.options.sortDesc.length > 0 ? this.options.sortDesc[0]:null,
                          ItemsPerPage:this.options.itemsPerPage, 
                          Page:this.options.page};      
            
      new CustomerPatientsService().get(pagedOptions)
                                        .then((value)=>{                                          
                                          this.patients = value.items;
                                          this.totalPatients = value.totalCount;
                                          this.loading = false;
                                          });

    },

    viewPatient(item) {
      this.editedIndex = this.patients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPatient = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.patients[this.editedIndex], this.editedItem);
      } else {
        this.patients.push(this.editedItem);
      }
      this.close();
    },
  },
});
</script>
