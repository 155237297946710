<template>
    <v-main>
      <PatientsList/>
    </v-main>
</template>

<script>
import PatientsList from '../components/PatientsList.vue';


export default ({

  components: { 
    PatientsList,
  },

  data: () => ({      
  }),
});
</script>
