import * as master from '../types/master';
import * as baseService from './BaseService';


export default class CustomerPatientsService extends baseService.BaseService {
   private url = '/api/CustomerPatients';
      
   constructor() {
     super();     
   } 

    async get(pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<master.CustomerPatient>>{          
      let response =  (await this.api.get(this.url,{params:{Page:pagedDataOptions.Page,ItemsPerPage:pagedDataOptions.ItemsPerPage,Search:pagedDataOptions.Search,SortBy:pagedDataOptions.SortBy,SortDesc:pagedDataOptions.SortDesc}})).data as baseService.PagedData<master.CustomerPatient>;         
      return response;
    } 
    
    async search(pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<master.CustomerPatient>>{
      let urlSearch = this.url + '/Search';          
      let response =  (await this.api.get(urlSearch,{params:{Page:pagedDataOptions.Page,ItemsPerPage:pagedDataOptions.ItemsPerPage,Search:pagedDataOptions.Search,SortBy:pagedDataOptions.SortBy,SortDesc:pagedDataOptions.SortDesc}})).data as baseService.PagedData<master.CustomerPatient>;         
      return response;
    }

    async warmUpSearchCache(): Promise<string>{
        let urlSearch = this.url + '/WarmUpSearchCache';
        return (await this.api.get(urlSearch)).data as string;
    }
}